<template>
  <div class="contact">
    <div class="contact-container">
      <h2>Contact Me</h2>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="form.name" required />
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="form.email" required />
        </div>
        <div class="form-group">
          <label for="message">Message:</label>
          <textarea id="message" v-model="form.message" required></textarea>
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    async submitForm() {
      try {
        const response = await fetch('send_email.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(this.form).toString()
        });

        const result = await response.json();
        alert(result.message);

        if (result.success) {
          this.form.name = '';
          this.form.email = '';
          this.form.message = '';
        }
      } catch (error) {
        alert('There was an error sending your message. Please try again later.');
      }
    }
  },
  metaInfo: {
    title: 'Miliware - Contact',
    meta: [
      { name: 'description', content: 'Contact Miliware for more information on our web and software development services.' },
      { name: 'keywords', content: 'web development, software development, Miliware, contact' },
      { property: 'og:title', content: 'Miliware - Contact' },
      { property: 'og:description', content: 'Contact Miliware for more information on our web and software development services.' },
      { property: 'og:image', content: require('@/assets/logo_with_title.png') },
      { property: 'og:url', content: 'https://www.miliware.com/contact' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Miliware - Contact' },
      { name: 'twitter:description', content: 'Contact Miliware for more information on our web and software development services.' },
      { name: 'twitter:image', content: require('@/assets/logo_with_title.png') }
    ]
  }
}
</script>

<style scoped>
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('@/assets/contact_background.jpg');
  background-size: cover;
  background-position: center;
  padding: 2rem;
}

.contact-container {
  background-color: rgba(44, 62, 80, 0.9); /* Dark Blue with opacity */
  color: #ECF0F1;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
}

h2 {
  color: #3498DB;
  margin-bottom: 1rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
}

button {
  background-color: #E74C3C;
  color: #ECF0F1;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

button:hover {
  background-color: #C0392B;
}
</style>
