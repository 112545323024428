<template>
  <header>
    <div class="header-content">
      <router-link to="/" class="logo-link">
        <img :src="require('@/assets/logo_with_title.png')" alt="Miliware" class="logo-title" />
      </router-link>
      <h1 class="title-text">Web and Software Development Services</h1>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<style scoped>
header {
  background-color: #2C3E50;
  color: #ECF0F1;
  padding: 1rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-link {
  flex-shrink: 0;
}

.logo-title {
  width: 200px; /* Adjust size as needed */
  height: auto;
}

.title-text {
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.5;
}
</style>
