<template>
  <div class="services">
    <div class="background">
      <div class="content">
        <h2>Our Services</h2>
        <p>
          We offer a range of web and software development services to help your business thrive.
        </p>
        <div class="service-section">
          <h3>Web Development</h3>
          <p>
            Our team specializes in building responsive and modern websites tailored to your business needs. Whether you need an e-commerce platform, a corporate website, or a personal blog, we have the expertise to deliver top-quality solutions.
          </p>
          <router-link to="/web-development"><button>Learn More</button></router-link>
        </div>
        <div class="service-section">
          <h3>Software Development</h3>
          <p>
            We develop custom software applications to streamline your business processes. From desktop applications to complex enterprise systems, we provide scalable and robust software solutions.
          </p>
          <router-link to="/software-development"><button>Learn More</button></router-link>
        </div>
        <div class="service-section">
          <h3>UI/UX Design</h3>
          <p>
            We design user interfaces that are not only visually appealing but also user-friendly. Our UX design ensures a seamless and enjoyable user experience across all devices.
          </p>
          <router-link to="/ui-ux-design"><button>Learn More</button></router-link>
        </div>
        <div class="consultation">
          <h3>Free Consultation</h3>
          <p>
            Contact us today for a free consultation on your web and software development needs. Our experts are here to help you find the best solutions for your business.
          </p>
          <router-link to="/contact"><button>Get Free Consultation</button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesView',
  metaInfo: {
    title: 'Miliware - Services',
    meta: [
      { name: 'description', content: 'Explore the range of web and software development services offered by Miliware.' },
      { name: 'keywords', content: 'web development, software development, UI/UX design, Miliware, services' },
      { property: 'og:title', content: 'Miliware - Services' },
      { property: 'og:description', content: 'Explore the range of web and software development services offered by Miliware.' },
      { property: 'og:image', content: require('@/assets/logo_with_title.png') },
      { property: 'og:url', content: 'https://www.miliware.com/services' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Miliware - Services' },
      { name: 'twitter:description', content: 'Explore the range of web and software development services offered by Miliware.' },
      { name: 'twitter:image', content: require('@/assets/logo_with_title.png') }
    ]
  }
}
</script>

<style scoped>
.services {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ECF0F1;
}

.background {
  background-image: url('@/assets/services_background.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.content {
  background-color: rgba(44, 62, 80, 0.8); /* Dark Blue with opacity */
  color: #ECF0F1;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.service-section {
  margin-top: 2rem;
  background-color: #34495E;
  padding: 1.5rem;
  border-radius: 8px;
}

.service-section h3 {
  color: #3498DB;
  margin-bottom: 1rem;
}

.service-section p {
  margin-bottom: 1rem;
}

.consultation {
  background-color: #3498DB;
  color: #ECF0F1;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
}

button {
  background-color: #E74C3C;
  color: #ECF0F1;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 4px;
}

button:hover {
  background-color: #C0392B;
}
</style>
