<template>
  <footer class="footer">
    <p>© 2024 Miliware. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer {
  background-color: #2C3E50;
  color: #ECF0F1;
  text-align: center;
  padding: 1rem;
  width: 100%;
}
</style>
