<template>
  <nav>
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/services">Services</router-link></li>
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MenuComponent'
}
</script>

<style scoped>
nav {
  background-color: #3498DB;
  color: #ECF0F1;
  padding: 1rem 2rem; /* Increased padding for better spacing */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around; /* Spacing between items */
  align-items: center;
}

li {
  margin: 0 1rem;
}

a {
  color: #ECF0F1;
  text-decoration: none;
  font-weight: bold; /* Bolder font for better visibility */
  padding: 0.5rem 1rem; /* Padding for clickable area */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

a:hover {
  background-color: #E74C3C;
  color: #FFFFFF; /* White text on hover for contrast */
}
</style>
