<template>
  <div class="home">
    <div class="background">
      <div class="content">
        <h2>Welcome to Miliware Web and Software Development Services</h2>
        <p>
          We provide top-notch web and software development services to help your business succeed.
        </p>
        <router-link to="/services"><button>Learn More</button></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  metaInfo: {
    title: 'Miliware - Home',
    meta: [
      { name: 'description', content: 'Welcome to Miliware. Your one-stop solution for web and software development services.' },
      { name: 'keywords', content: 'web development, software development, Miliware, home' },
      { property: 'og:title', content: 'Miliware - Home' },
      { property: 'og:description', content: 'Welcome to Miliware. Your one-stop solution for web and software development services.' },
      { property: 'og:image', content: require('@/assets/logo_with_title.png') },
      { property: 'og:url', content: 'https://www.miliware.com' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Miliware - Home' },
      { name: 'twitter:description', content: 'Welcome to Miliware. Your one-stop solution for web and software development services.' },
      { name: 'twitter:image', content: require('@/assets/logo_with_title.png') }
    ]
  }
}
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ECF0F1;
}

.background {
  background-image: url('@/assets/homepage_background.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: rgba(44, 62, 80, 0.8); /* Dark Blue with opacity */
  color: #ECF0F1;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

button {
  background-color: #3498DB;
  color: #ECF0F1;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

button:hover {
  background-color: #E74C3C;
}
</style>
