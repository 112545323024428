<template>
    <div class="ui-ux-design">
      <div class="background">
        <div class="content">
          <h2>UI/UX Design Services</h2>
          <p>
            We design user interfaces that are not only visually appealing but also user-friendly. Our UX design ensures a seamless and enjoyable user experience across all devices.
          </p>
          <p>
            Our designers focus on creating intuitive and engaging experiences that keep your users coming back.
          </p>
          <!-- Add more detailed content as needed -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UiUxDesignView',
    metaInfo: {
      title: 'Miliware - UI/UX Design',
      meta: [
        { name: 'description', content: 'Learn more about our UI/UX design services at Miliware.' },
        { name: 'keywords', content: 'UI/UX design, user interface, user experience, Miliware' },
        { property: 'og:title', content: 'Miliware - UI/UX Design' },
        { property: 'og:description', content: 'Learn more about our UI/UX design services at Miliware.' },
        { property: 'og:image', content: require('@/assets/logo_with_title.png') },
        { property: 'og:url', content: 'https://www.miliware.com/ui-ux-design' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Miliware - UI/UX Design' },
        { name: 'twitter:description', content: 'Learn more about our UI/UX design services at Miliware.' },
        { name: 'twitter:image', content: require('@/assets/logo_with_title.png') }
      ]
    }
  }
  </script>
  
  <style scoped>
  .ui-ux-design, .background {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .background {
    background-image: url('@/assets/ui_ux_design_background.jpg');
    padding: 2rem;
  }
  
  .content {
    background-color: rgba(44, 62, 80, 0.8);
    color: #ECF0F1;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 800px;
    width: 100%;
  }
  </style>
  