<template>
    <div class="web-development">
      <div class="background">
        <div class="content">
          <h2>Web Development Services</h2>
          <p>
            Our team specializes in building responsive and modern websites tailored to your business needs. Whether you need an e-commerce platform, a corporate website, or a personal blog, we have the expertise to deliver top-quality solutions.
          </p>
          <p>
            We use the latest technologies and best practices to ensure your website is fast, secure, and scalable.
          </p>
          <!-- Add more detailed content as needed -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WebDevelopmentView',
    metaInfo: {
      title: 'Miliware - Web Development',
      meta: [
        { name: 'description', content: 'Learn more about our web development services at Miliware.' },
        { name: 'keywords', content: 'web development, Miliware, responsive websites, e-commerce, corporate websites, personal blogs' },
        { property: 'og:title', content: 'Miliware - Web Development' },
        { property: 'og:description', content: 'Learn more about our web development services at Miliware.' },
        { property: 'og:image', content: require('@/assets/logo_with_title.png') },
        { property: 'og:url', content: 'https://www.miliware.com/web-development' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Miliware - Web Development' },
        { name: 'twitter:description', content: 'Learn more about our web development services at Miliware.' },
        { name: 'twitter:image', content: require('@/assets/logo_with_title.png') }
      ]
    }
  }
  </script>
  
  <style scoped>
  .web-development, .background {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .background {
    background-image: url('@/assets/web_development_background.jpg');
    padding: 2rem;
  }
  
  .content {
    background-color: rgba(44, 62, 80, 0.8);
    color: #ECF0F1;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 800px;
    width: 100%;
  }
  </style>
  