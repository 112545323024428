<template>
    <div class="software-development">
      <div class="background">
        <div class="content">
          <h2>Software Development Services</h2>
          <p>
            We develop custom software applications to streamline your business processes. From desktop applications to complex enterprise systems, we provide scalable and robust software solutions.
          </p>
          <p>
            Our team is experienced in a variety of programming languages and frameworks to deliver the best possible product for your needs.
          </p>
          <!-- Add more detailed content as needed -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SoftwareDevelopmentView',
    metaInfo: {
      title: 'Miliware - Software Development',
      meta: [
        { name: 'description', content: 'Learn more about our software development services at Miliware.' },
        { name: 'keywords', content: 'software development, custom software, enterprise systems, Miliware' },
        { property: 'og:title', content: 'Miliware - Software Development' },
        { property: 'og:description', content: 'Learn more about our software development services at Miliware.' },
        { property: 'og:image', content: require('@/assets/logo_with_title.png') },
        { property: 'og:url', content: 'https://www.miliware.com/software-development' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Miliware - Software Development' },
        { name: 'twitter:description', content: 'Learn more about our software development services at Miliware.' },
        { name: 'twitter:image', content: require('@/assets/logo_with_title.png') }
      ]
    }
  }
  </script>
  
  <style scoped>
  .software-development, .background {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .background {
    background-image: url('@/assets/software_development_background.jpg');
    padding: 2rem;
  }
  
  .content {
    background-color: rgba(44, 62, 80, 0.8);
    color: #ECF0F1;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 800px;
    width: 100%;
  }
  </style>
  