<template>
  <div class="about">
    <div class="profile-container">
      <div class="company-info">
        <h2>About Miliware</h2>
        <p>
          Miliware, founded in May 2024, is a dynamic and innovative company dedicated to delivering top-notch web and software development services. With a passion for technology and a commitment to excellence, Miliware specializes in creating custom solutions tailored to the unique needs of each client. Our mission is to help businesses thrive in the digital age through cutting-edge applications and websites that enhance user experience and drive growth.
        </p>
      </div>
      <div class="profile">
        <img :src="require('@/assets/profile_pic.jpg')" alt="Nemanja Milivojevic" class="profile-pic" />
        <div class="profile-info">
          <h2>Nemanja Milivojevic (Founder)</h2>
          <p>
            I offer frontend and backend development services in web and software development. I have extensive skills in building apps for Windows OS and some for Android/iOS. Websites can be custom-built with the appearance and functionalities completely up to the client's desire.
          </p>
          <h3>Objective</h3>
          <p>
            With a keen interest in migrating to Norway, I aim to contribute to the growth and success of an innovative organization where I can further hone my abilities and make a significant impact.
          </p>
          <h3>Education</h3>
          <p>
            Department School of Information and Communication Technologies
          </p>
          <p>
            Engineer Of Electrical And Computer Engineering – Bachelor
          </p>
          <h3>Projects</h3>
          <div class="project">
            <h4>Scrollio Language Learning App</h4>
            <p><strong>April 2024</strong></p>
            <p>
              Developed "Scrollio," a cross-platform language learning application using .NET, enabling users on Windows to upload text files in foreign languages, marking words as known or to be learned, which facilitates vocabulary expansion through interactive learning.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  metaInfo: {
    title: 'Miliware - About Us',
    meta: [
      { name: 'description', content: 'Learn more about Miliware and our web and software development services.' },
      { name: 'keywords', content: 'web development, software development, Miliware, about us' },
      { property: 'og:title', content: 'Miliware - About Us' },
      { property: 'og:description', content: 'Learn more about Miliware and our web and software development services.' },
      { property: 'og:image', content: require('@/assets/logo_with_title.png') },
      { property: 'og:url', content: 'https://www.miliware.com/about' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Miliware - About Us' },
      { name: 'twitter:description', content: 'Learn more about Miliware and our web and software development services.' },
      { name: 'twitter:image', content: require('@/assets/logo_with_title.png') }
    ]
  }
}
</script>

<style scoped>
.about {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: #125f92; /* Background color for the sides */
  padding: 2rem;
}

.profile-container {
  background-color: #ECF0F1; /* Background color for the profile container */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width */
  margin-bottom: 2rem; /* Add margin-bottom to avoid bleeding into the footer */
}

.company-info {
  margin-bottom: 2rem;
}

.profile {
  display: flex;
  align-items: flex-start;
  background-color: #2C3E50;
  color: #ECF0F1;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.profile-pic {
  border-radius: 8px;
  margin-right: 2rem;
  opacity: 0.9;
  flex-shrink: 0;
}

.profile-info {
  flex: 1;
}

h2 {
  color: #3498DB;
  margin-bottom: 1rem;
}

h3 {
  color: #E74C3C;
  margin-top: 1.5rem;
}

.project {
  margin-bottom: 1rem;
}

p {
  line-height: 1.6;
  margin-bottom: 1rem;
}
</style>
