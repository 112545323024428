import { render, staticRenderFns } from "./ServicesView.vue?vue&type=template&id=23607171&scoped=true"
import script from "./ServicesView.vue?vue&type=script&lang=js"
export * from "./ServicesView.vue?vue&type=script&lang=js"
import style0 from "./ServicesView.vue?vue&type=style&index=0&id=23607171&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23607171",
  null
  
)

export default component.exports