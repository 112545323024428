import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServicesView from '../views/ServicesView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import WebDevelopmentView from '../views/WebDevelopmentView.vue'
import SoftwareDevelopmentView from '../views/SoftwareDevelopmentView.vue'
import UiUxDesignView from '../views/UiUxDesignView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/web-development',
    name: 'WebDevelopment',
    component: WebDevelopmentView
  },
  {
    path: '/software-development',
    name: 'SoftwareDevelopment',
    component: SoftwareDevelopmentView
  },
  {
    path: '/ui-ux-design',
    name: 'UiUxDesign',
    component: UiUxDesignView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
